<template>
  <div class="container">
    <div class="user-nav">
      <Search
        :isClass="true"
        :isSearch="true"
        :term="false"
        :isBtn="true"
        :classlist="constData.classlist"
        @handleQuery="handleQuery"
        ref="search"
      />
    </div>

    <div class="main">
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <h3>学生综素档案</h3>
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="userName" label="学生姓名"></el-table-column>
        <el-table-column
          prop="loginName"
          label="学籍号"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="deptName"
          label="班级"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="type301"
          label="思想品德"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="type309"
          label="学业水平"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="type320"
          label="身心健康"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="type333"
          label="艺术素养"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="type341"
          label="社会实践(初中累计)"
          width="200"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleDetail(scope.row)" type="text" size="small"
              >综素档案</el-button
            >
            <el-button @click="downloadDoc(scope.row)" type="text" size="small"
              >下载档案</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getSummary } from "@/api/teacher/quality/summary";
import axios from "axios";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      total:0 ,
      queryParams: {
        loginNameOrUserName: "",
        deptId: "",
        pageSize: 100,
      },
      tableData: [],
      constData: {
        termlist: [],
        classlist: [],
      },
    };
  },
  created() {
    this.getFirst();
  },
  components: { Search },
  methods: {
    getFirst() {
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.constData.classlist = res.data;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init("", this.queryParams.deptId);
        this.getList();
      });
    },
    getList() {
      getSummary(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size
      });
    },
    handleQuery(data) {
      this.queryParams.deptId = data.deptId;
      this.queryParams.loginNameOrUserName = data.userName;
      this.getList();
    },
    handleDetail(row) {
      let { userId } = row;
      sessionStorage.setItem("userIdFromStudentDocBtn", userId);
      this.$router.push("archivesManageDoc");
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
    // 下载
    downloadDoc(row) {
      axios({
        baseURL: process.env.VUE_APP_API,
        url: "/shida-user-biz/upload/downStudentPdf",
        method: "post",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
        },
        data: {
          userId: row.userId,
        },
        // responseType: "blob",
      }).then((res) => {
        // console.log(res.data);
        let blob = this.dataURItoBlob(res.data.data);
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = "综素档案.pdf"; //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      });
    },
    // base64文件流转为blob
    dataURItoBlob(dataURI) {
      // data:application/pdf;base64,
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]; // mime类型
      var byteString = atob(dataURI.split(",")[1]); //base64 解码
      var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer); //创建视图

      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([intArray], { type: mimeString });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-nav {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 0.15);
  height: 63px;
  box-sizing: border-box;
}
.main {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 32px;
  color: #616266;
  margin: 0;
}
</style>
